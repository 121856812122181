import React from 'react'
import { LayoutDefault } from '../../components/organisms/Layout/LayoutDefault'
import { sideNavDataCompany } from '../../components/data/sideNavData'
import { SEO } from '../../components/data/SEO'
import { Philosophy } from '../../components/organisms/Philosophy/Philosophy'
import { ENGLISH_TITLE } from '../../components/data/English'

const Page = () => {
  return (
    <LayoutDefault
      heading="企業理念・行動指針"
      headingEnglish={ENGLISH_TITLE.Philosophy}
      BreadcrumbData={[
        { title: '企業情報', url: '/company' },
        { title: '企業理念・行動指針', url: '' },
      ]}
      sideNavHeading="企業情報"
      sideNavList={sideNavDataCompany}
    >
      <Philosophy />
    </LayoutDefault>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEO title="企業理念・行動指針" url="/company/philosophy" />
)
